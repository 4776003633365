import Introduction from 'components/Introduction';
import Footnotes from 'components/Footnotes';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectFade } from 'swiper/modules';
import Media from 'components/Media';
import React from 'react';
import classNames from 'classnames';
import GalleryTop from '../elements/GalleryTop';
import { useState } from 'react';

import useExpand from 'lib/hooks/useExpand';

const Gallery2a = ({ settings, introduction, items, footnotes, attr }) => {
    const [swiperOne, setSwiperOne] = useState(null);
    const [count, setCount] = useState(1);
    const nextSlide = () => {
        if (count >= items.length) {
            swiperOne.slideTo(0);
        }
        if (count < items.length) {
            swiperOne.slideNext();
        }
    };
    const prevSlide = () => {
        swiperOne.slidePrev();
    };
    const { expand } = useExpand();
    return (
        <section
            className={classNames('section', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <Introduction {...introduction} />
                <div className="gallery gallery--2a">
                    <GalleryTop
                        count={count}
                        items={items}
                        prevSlide={prevSlide}
                        nextSlide={nextSlide}
                        reduceCount
                    />
                    <Swiper
                        className={classNames('gallery__main', {
                            'gallery__main--expand': expand,
                        })}
                        slidesPerView={1}
                        spaceBetween={0}
                        breakpoints={{
                            420: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                        }}
                        modules={[Pagination, EffectFade]}
                        pagination={{
                            type: 'progressbar',
                        }}
                        onSwiper={setSwiperOne}
                        onSlideChange={(swiper) => {
                            setCount(swiper.realIndex + 1);
                        }}
                    >
                        {items.map((item, index) => {
                            const { media } = item;
                            return (
                                <SwiperSlide
                                    key={index}
                                    className="gallery__slide"
                                >
                                    <Media
                                        wrapperClassName={'gallery__media'}
                                        layout="fill"
                                        objectFit={'contain'}
                                        objectPosition={'center'}
                                        media={media}
                                        defaultBackground={'white'}
                                        disableCaption
                                        disableElevation
                                    />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
                {footnotes?.value && (
                    <Footnotes className="mt-14" footnotes={footnotes} />
                )}
            </div>
        </section>
    );
};

export default Gallery2a;
