import React, { useEffect, useState } from 'react';
import Introduction from 'components/Introduction';
import Footnotes from 'components/Footnotes';
import Media from 'components/Media';
import Pagination from 'components/Pagination';
import classNames from 'classnames';


const Gallery3a = ({settings, introduction, items, footnotes, attr}) => {

	const [paginatedItems, setPaginatedItems] = useState([]);
	const [pages, setPages] = useState(1);
	const [offset, setOffset] = useState(0);
	const perPage = 12;

	useEffect(() => {
		setPages(Math.ceil(items.length / perPage));
	}, []);

	useEffect(() => {
		setPaginatedItems(items.slice(offset, offset + perPage));
	}, [offset]);

	const handleChangePage = page => {
		setOffset(page === 1 ? 0 : (page - 1) * perPage);
	};

	return (
		<section
			
			className={classNames('section', {
				...settings
			})}
			{...attr}
		>
			<div className='container'>
				<Introduction {...introduction} />
				<div className='grid-md-3 grid-2 gap-2 row-gap-md-6 gallery--3a'>
					{paginatedItems.map((item, index) => {
						const { media } = item;
						return (
							<Media
								wrapperClassName={'gallery__media'}
								layout='fill'
								objectFit={'contain'}
								objectPosition={'center'}
								key={index}
								media={media}
								defaultBackground={'white'}
								disableCaption
								disableElevation
							/>
						);
					})}
				</div>
				{pages > 1 && (
					<Pagination className={'mt-14'} pages={pages} onChangePage={handleChangePage} />
				)}
				{footnotes?.value && <Footnotes className='mt-14' footnotes={footnotes} />}
			</div>
		</section>
	);
};

export default Gallery3a;
